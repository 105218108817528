import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Egypt",
  "subtitle": "October 2017",
  "category": "Middle East"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Local SIM - I picked one from up Vodafone but they send a lot of spam`}</li>
      <li parentName="ul">{`Bring toilet paper, baby wipes, and hand sanitizer! It’s very dusty and dirty`}</li>
      <li parentName="ul">{`You WILL get hustled by kids and adults! Don't forget to bargin if you purchase`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Falafel"
        }}>{`Falafel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Feteer_meshaltet"
        }}>{`Feteer Meshaltet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hawawshi"
        }}>{`Hawawshi`}</a>{` - Eat it in Alexandria!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kanafeh"
        }}>{`Kanafeh`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Kushari"
        }}>{`Kushari`}</a>{` - Don’t forget the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Chili_sauce_and_paste#Levant"
        }}>{`shatta sauce`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Shawarma"
        }}>{`Shawarma`}</a></li>
      <li parentName="ul">{`Nubian coffee`}</li>
    </ul>
    <h2 {...{
      "id": "cairo"
    }}>{`Cairo`}</h2>
    <ul>
      <li parentName="ul">{`Uber everywhere but beware of traffic`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hRx3KXLxtHDER9uN6"
        }}>{`El Abd Patisserie `}</a>{` - Very popular pastries!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RYiZuUFNNBrBp1DK9"
        }}>{`Koshary Abou Tarek`}</a>{` - Superb!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/zhWCKnQB3me8UnyD6"
        }}>{`Egyptian pancake house`}</a>{` - So-so`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mijpafGC3HdLaeQp7"
        }}>{`Taza`}</a>{` - There were lots of people lining up for shawarma so I joined too`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/cyQ16nbe2RmpCvAt7"
        }}>{`KFC`}</a>{`  - This was a community care location when I was there, where they hire the hearing and speech impaired`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/18hGgL1TEGhdUof67"
        }}>{`Salah El Din Citadel`}</a>{` - Great view of the city`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/UQTSJcxK9YRi8AV97"
        }}>{`Islamic Art Museum`}</a>{` - Limited English translations when I went`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/625pTEpfdNZs82fx6"
        }}>{`Prince Mohamed Ali Palace`}</a>{` - Spent around ~2 hours here`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2LJ6PQzVPnn1bxoM6"
        }}>{`The Great Pyramid of Giza`}</a>{` - Very narrow and hot inside`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/nWB9wjV5mR4UfEZ3A"
        }}>{`Khan al-Khalili`}</a>{` - Don't forget to bargain`}</li>
    </ul>
    <h4 {...{
      "id": "coptic-sector"
    }}>{`Coptic Sector`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tXSip78AiDS8m6yF6"
        }}>{`Coptic Museum`}</a>{` - Hired an english tour guide`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/H2RGJQvy6WTBAQgv9"
        }}>{`Hanging Church`}</a>{` - One of the oldest churches`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YRDzD5wAiveTnhTn8"
        }}>{`St. George’s Church`}</a>{` - Was rebuilt after a fire`}</li>
    </ul>
    <h2 {...{
      "id": "alexandria"
    }}>{`Alexandria`}</h2>
    <ul>
      <li parentName="ul">{`I only did a day trip`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/ZtZwCXwZxPC2"
        }}>{`Azza`}</a>{` - Tasted like sorbet`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/T9UpCu83e822"
        }}>{`Lion Village`}</a>{` - It's a restaurant but also a zoo? I had `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Feteer_meshaltet"
        }}>{`Feteer Meshaltet`}</a>{` here since they used a special oven (or I was told). A single order can probably serve 4. Try it with white honey, black honey and cheese as they're all different. They also sold lots of white honey by the jar.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bPFcEtySMYfTp72s7"
        }}>{`Catacombs of Kom el Shoqafa`}</a>{` - One of the seven wonders of the world! No cameras allowed (but many were using their smart phones...)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/D5MnUzHcWFjL5tVR8"
        }}>{`Bibliotheca Alexandria`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Entry ticket included a guided tour but exhibitions were extra`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.bibalex.org/en/center/details/manuscriptsmuseum"
            }}>{`Manuscripts Museum`}</a>{` was nice`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pGwiCWMbxvd8KVM87"
        }}>{`Alexandria National Museum`}</a>{` - Place is enormous; I didn't have enough time to see it through`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ofdZu5q4wrRx8xtm6"
        }}>{`Citadel of Qaitbay`}</a></li>
    </ul>
    <h2 {...{
      "id": "aswan"
    }}>{`Aswan`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://abelatrains.com/"
        }}>{`Watania sleeper train`}</a>{` have shared rooms. Bed was pretty small and breakfast was boring. Bring toilet paper.`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Try a home-cooked Nubian family dinner if you get a chance`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nNcLswkdqe1u7N1y6"
        }}>{`Nubian Dream Restaurant and Cafe`}</a>{` - Camel and pigeon dishes here if you’re adventurous. Try their Nubian coffee.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/sPKxj1XdtogQCXzs6"
        }}>{`Abu Simbel`}</a>{` - I went with a private tour but had to wake up very early! Spent a lot of time on the road since it's in the middle of no where. There's NO cover, so bring sunscreen and dress appropriately. The temples were cool so I thought it was worth visting.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/R4tgg8LT8KvmE9Ft7"
        }}>{`Philae Temple`}</a></li>
    </ul>
    <h2 {...{
      "id": "nile"
    }}>{`Nile`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Felucca"
        }}>{`Felucca Ride`}</a>{` - Spent a night on the boat; Was immediately hustled by kid sellers early morning`}</li>
    </ul>
    <h2 {...{
      "id": "luxor"
    }}>{`Luxor`}</h2>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/G27et6buDKP789Wb6"
        }}>{`Pizza Roma.it`}</a>{` - So-so`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ngceaG5QGAx5Fmis7"
        }}>{`Valley of the Kings`}</a>
        <ul parentName="li">
          <li parentName="ul">{`They don't accept student IDs without a date on it`}</li>
          <li parentName="ul">{`Bring repellent cause there were flies everywhere`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/u2Y5U2fW1DbTdhxw7"
            }}>{`Rameses III`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/5oZdNgzTqMzRbZ9Y9"
            }}>{`Rameses IV`}</a>{` - Well preserved and very nice!`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/D5fKptsfq3Zvg8qbA"
            }}>{`Rameses V & VI`}</a>{` - Cost extra; Similar to IV`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/6B9rBWXdwWpMUvo6A"
            }}>{`Rameses IX`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/nF76592dDEYpcVW98"
            }}>{`Merenptah`}</a>{` - Long flight of stairs`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/v8fKGSwdDL8haQEAA"
            }}>{`Tutankhamon`}</a>{` - Cost extra; Was tiny and I didn't think it was worth it`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wg5T4bHJoQ3WmPEn8"
        }}>{`Karnak Temple`}</a>{` - Was much cooler than I thought!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w1iLFjbuGGUyA2N28"
        }}>{`Colossi of Memnon`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      